<template>
  <div class="nr-msg">
    <div class="center">
      <div class="tit">
        <h2>{{ this.menu | transMenuName(this.menuType) }}</h2>
      </div>
      <CompMarquee />
      <div style="display: none" id="hidden-content">
        <h2>訊息內容</h2>
        <br />
        <textarea
          v-model="serviceContent"
          style="color: black; width: 500px; height: 300px; padding: 10px"
        ></textarea>
        <div>
          <a
            v-on:click="vodSendMsg"
            style="
              display: inline-block;
              width: 100px;
              height: 20px;
              text-align: center;
              background: red;
              line-height: 20px;
              margin-top: 10px;
              margin-left: 0px;
              margin-right: 8px;
              cursor: pointer;
            "
          >
            <span>送出</span>
          </a>
        </div>
      </div>
      <div style="display: none" id="hidden-content-1">
        <div style="width: 600px" v-if="objMsg.intType == 1">
          <h2 style="color: yellow">您的問題</h2>
          <div v-html="objMsg.strCont"></div>
          <br />
          <h2 style="color: purple">客服答覆</h2>
          <div v-html="objMsg.strReply"></div>
        </div>
        <div style="width: 600px" v-if="objMsg.intType == 2">
          <h2 style="color: purple">客服通知</h2>
          <div v-html="objMsg.strReply"></div>
        </div>
      </div>
      <div class="list" style="min-height: 700px">
        <div class="fx">
          <span
            v-on:click="vodSwitch('1')"
            :class="{ fst: true, on: strCat == '1' }"
            >訊息中心
            <div class="readCnt" v-show="ActivityMsgNoReadCount > 0">
              {{
                Number(ActivityMsgNoReadCount) > 99
                  ? "99+"
                  : ActivityMsgNoReadCount
              }}
            </div>
          </span>
          <span v-on:click="vodSwitch('2')" :class="{ on: strCat == '2' }"
            >站務規章文件</span
          >
          <span v-on:click="vodSwitch('3')" :class="{ on: strCat == '3' }"
            >站務使用說明</span
          >
          <span
            v-if="loginState"
            style="width: 200px"
            v-on:click="vodSwitch('4')"
            :class="{ brn: true, on: strCat == '4' }"
            >站內信
            <div class="readCnt" v-show="msgNoReadCount > 0">
              {{ Number(msgNoReadCount) > 99 ? "99+" : msgNoReadCount }}
            </div></span
          >
        </div>
        <a
          data-fancybox
          data-src="#hidden-content"
          v-if="strCat == '4'"
          style="
            display: inline-block;
            width: 100px;
            height: 40px;
            text-align: center;
            background: red;
            line-height: 40px;
            margin-top: 20px;
            margin-left: 20px;
            margin-right: 8px;
            cursor: pointer;
          "
        >
          <span>聯繫客服</span>
        </a>
        <ul>
          <li v-for="(item, index) in aryList" :key="index">
            <a v-on:click="vodMsg(item)" style="cursor: pointer">
              <h2>{{ item.title }}</h2>
              <div class="bar">
                <div v-if="strCat == '1'">
                  <span>公 告</span>
                  <span
                    style="background: green"
                    v-if="item.read_status === 1"
                    v-show="loginState"
                    >已 讀</span
                  >
                  <span
                    style="background: red"
                    v-if="item.read_status === 0"
                    v-show="loginState"
                    >未 讀</span
                  >
                  {{ item.start_at | date }}
                </div>

                <span v-if="strCat == '2'" style="width: 100px">
                  站務規章文件</span
                >
                <span v-if="strCat == '3'" style="width: 120px">
                  站務使用說明文件</span
                >
                <span
                  v-if="strCat == '4' && item.type == '會員詢問'"
                  style="width: 80px"
                >
                  站內信</span
                >
                <span
                  v-if="strCat == '4' && item.type == '客服通知'"
                  style="width: 80px; background: purple"
                >
                  客服通知</span
                >
                <div v-if="strCat == '4'">
                  <span
                    v-if="item.mstatus == '未讀' && item.status == '已回信'"
                    style="background: red"
                    >未讀</span
                  >
                  <span
                    v-if="item.mstatus == '已讀' && item.status == '已回信'"
                    style="background: green"
                    >已讀</span
                  >
                  <span
                    v-if="item.status == '未回信' && item.type == '會員詢問'"
                    style="background: red"
                    >未回信</span
                  >
                  <span
                    v-if="item.status == '已回信' && item.type == '會員詢問'"
                    style="background: green"
                    >已回信</span
                  >
                  {{ item.created_at | date }}
                </div>
              </div>
              <p v-if="strCat == '1'">{{ item.content2 }}</p>
              <p v-if="strCat == '4'">
                {{ transContentToTitle(item.content) }}
              </p>
              <p v-if="strCat == '2' || strCat == '3'">點擊開啟檔案連結</p>
            </a>
          </li>
        </ul>
        <CompPager
          v-if="pageData"
          @evtpagerchg="vodGetPage"
          :int-per-page="page.pagesize"
          :int-tol-cnt="pageData.allnums"
          :int-cur-page="page.currentPage"
          ref="pager"
        ></CompPager>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
    CompPager: require("@/components/parts/pager").default,
  },
  computed: {
    ...mapGetters({
      loginState: "loginState",
    }),
    ...mapState({
      activityInfoList: (state) => state.news.activityInfoList,
      specificationList: (state) => state.website.specificationList,
      instructionList: (state) => state.website.instructionList,
      messageList: (state) => state.notice.messageList,
      pageData: (state) => state.app.pageData,
      menu: (state) => state.website.menu,
      ActivityMsgNoReadCount: (state) => state.news.ActivityMsgNoReadCount,
      msgNoReadCount: (state) => state.notice.msgNoReadCount,
    }),
  },
  data() {
    const initPage = { pagesize: 10, currentPage: 1 };
    return {
      initPage,
      page: Object.assign({}, initPage),
      //
      intPerPage: 10,
      intTolCnt: 0,
      intCurPage: 1,
      objMsg: {
        strCont: "",
        bolSend: false,
      },
      strCat: this.$route.params.strCat || "1",
      aryList: [],
      objMapAera: {
        other: "其他 使用說明文件",
        meminfo: "會員中心 使用說明文件",
        dbuy: "購物商城 使用說明文件",
        lott: "活動專區 使用說明文件",
        cbuylist: "結帳優惠 使用說明文件",
        gtrans: "直購貼紙 使用說明文件",
        msg: "重要訊息 使用說明文件",
        games: "遊戲區 使用說明文件",
      },
      serviceContent: "",
      menuType: "msg",
    };
  },
  methods: {
    vodGetPage(page) {
      this.page.currentPage = page;
      this.vodGetMsg(true);
    },
    async getActivityInfos(data) {
      await this.$store.dispatch("news/getActivityInfos", {
        _d: data,
        routeName: this.$route.name,
      });
    },
    async getSpecificationList(data) {
      await this.$store.dispatch("website/getSpecificationList", data);
    },
    async getInstructionList(data) {
      await this.$store.dispatch("website/getInstructionList", data);
    },
    async getMessageList(data) {
      await this.$store.dispatch("notice/getMessageList", {
        _d: data,
        routeName: this.$route.name,
      });
    },
    async vodMsg(obj) {
      switch (this.strCat) {
        case "1":
          const date = {
            token: sessionStorage.token,
            info_id: obj.id,
          };
          $.fancybox.open(`<div>${obj.content}</div>`);
          if (this.loginState && obj.read_status == 0) {
            await this.$store.dispatch("news/readActivityMsg", date);
            this.vodGetMsg(false);
          }
          break;
        case "2":
          $.fancybox.open({
            caption: obj.title,
            src: this.$baseImgUrl + obj.url,
            type: "iframe",
            titleShow: true,
            opts: {},
          });
          break;
        case "3":
          $.fancybox.open({
            caption: obj.title,
            src: this.$baseImgUrl + obj.url,
            type: "iframe",
            title: obj.strTTL,
            titleShow: true,
            opts: {},
          });
          break;
        case "4":
          if (obj.image) {
            $.fancybox.open({
              src: this.$baseImgUrl + obj.image,
              type: "image",
              opts: {
                caption: obj.reply_content,
              },
            });
          } else {
            if (obj.type === "會員詢問") {
              const reply = obj.reply_content
                ? obj.reply_content
                : "儘速回覆中...";
              var fancyContent = `
            <div style="width:690px">
              <div style="color: yellow">您的問題</div><br/>
              <div>${obj.content}</div><br/><br/>
              <div style="color: purple">客服答覆</div><br/>
              <div>${reply}</div>
            </div>`;
            } else {
              var fancyContent = `
            <div style="width:690px">
              <div style="color: purple">客服通知</div><br/>
              <div>${obj.content}</div>
            </div>`;
            }
            $.fancybox.open(fancyContent);
          }

          if (obj.status === "未回信") return;
          const data = {
            token: sessionStorage.token,
            msgID: obj.id,
          };
          await this.$store.dispatch("notice/readMessage", data);
          obj.mstatus = "已讀";
          this.vodGetMsg(false);
          break;
      }
    },
    //
    async vodSendMsg() {
      if (this.serviceContent == "") {
        this.$root.$snotify.error("請填寫訊息內容!");
        return;
      }
      const data = {
        token: sessionStorage.token,
        content: this.serviceContent.replaceAll("\n", "<br/>"),
      };
      const status = await this.$store.dispatch("notice/service", data);
      if (status) {
        await this.vodGetMsg(true);
        this.serviceContent = "";
        $.fancybox.close();
      }
    },
    vodSwitch(str) {
      this.strCat = str;
      this.page = this.initPage;
      this.vodGetMsg(true);
    },
    async vodGetMsg(clear) {
      clear && (this.aryList = []);
      const data = {
        page: this.page.currentPage,
        pagesize: this.page.pagesize,
      };
      switch (this.strCat) {
        case "1":
          {
            this.loginState && (data.token = sessionStorage.token);
            await this.getActivityInfos(data);
            this.aryList = this.activityInfoList;
          }
          break;
        case "2":
          {
            await this.getSpecificationList(data);
            this.aryList = this.specificationList;
          }
          break;
        case "3":
          {
            await this.getInstructionList(data);
            this.aryList = this.instructionList;
          }
          break;
        case "4":
          {
            await this.getMessageList(data);
            this.aryList = this.messageList;
          }
          break;
      }
    },
    transContentToTitle(content) {
      const index = content.indexOf("<br/>");
      if (index === -1) {
        return content;
      } else {
        return content.substr(0, index);
      }
    },
  },
  async mounted() {
    await this.vodGetMsg(true);
  },
};
</script>
<style scoped>
@import "~@/assets/css/message.css";
.readCnt {
  position: absolute;
  background: #f00;
  font-size: 15px;
  padding: 6px;
  border-radius: 50%;
  top: -20px;
  display: inline-block;
  line-height: 10px;
}
</style>
