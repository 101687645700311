import request from "@/plugins/request";

const baseUrl = "member";
function register(data) {
  return request({
    url: baseUrl + "/register",
    method: "POST",
    data
  });
}
function shareView(data) {
  return request({
    url: baseUrl + "/shareView",
    method: "POST",
    data
  });
}
function login(data) {
  return request({
    url: baseUrl + "/login",
    method: "POST",
    data
  });
}
function logout(data) {
  return request({
    url: baseUrl + "/logout",
    method: "POST",
    data
  });
}
function getuser(data) {
  return request({
    url: baseUrl + "/getuser",
    method: "POST",
    data
  });
}
function edituser(data) {
  return request({
    url: baseUrl + "/edituser",
    method: "POST",
    data
  });
}

export const memberApi = {
  register,
  login,
  logout,
  getuser,
  edituser,
  shareView
};
