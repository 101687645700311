import request from "@/plugins/request";

const baseUrl = "recharge";
function getRechargeMethods(data) {
  return request({
    url: baseUrl + "/recharge-methods",
    method: "POST",
    data
  });
}
function getRechargeOrder(data) {
  return request({
    url: baseUrl + "/recharge-order",
    method: "POST",
    data
  });
}
function checkoutRecharge(data) {
  return request({
    url: baseUrl + "/recharge-checkout",
    method: "POST",
    data
  });
}

export const rechargeApi = {
  getRechargeMethods,
  getRechargeOrder,
  checkoutRecharge
};
