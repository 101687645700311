<template>
  <div>
    <div class="nr-mem">
      <div class="center">
        <div class="tit">
          <h2>會員中心</h2>
        </div>
        <CompMarquee />
        <div class="ctnr">
          <CompMenNavmem :keyy="$route.name" />
          <div class="r-content">
            <h2>基本資料</h2>
            <div class="bg" v-if="userInfo.name">
              <el-row type="flex">
                <h3>Hi , {{ userInfo.name }} ({{ userInfo.lv }})</h3>
                <div style="width: 70%; padding: 10px 10px 0">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="20"
                    :percentage="
                      parseInt(
                        ((userInfo.empiric * 1) /
                          (userInfo.totalnowempiric * 1)) *
                          100
                      )
                    "
                  ></el-progress>
                </div>
                <h3 style="color: #409eff">
                  {{ userInfo.empiric }} / {{ userInfo.totalnowempiric }}
                </h3>
              </el-row>
              <dl v-if="!webMode">
                <dd>
                  希望幣:
                  <span class="c1">{{ userInfo.D | toThousandFilter }}</span>
                </dd>
                <dd>
                  福利金幣:
                  <span class="c2">{{ userInfo.G | toThousandFilter }}</span>
                </dd>
                <dd>
                  福利銀幣:
                  <span class="c3">{{ userInfo.S | toThousandFilter }}</span>
                </dd>
                <dd>
                  福利銅幣:
                  <span class="c4">{{ userInfo.C | toThousandFilter }}</span>
                </dd>
              </dl>
              <div class="list" v-if="!webMode">
                <p>
                  分享串連網址:
                  <input
                    type="text"
                    class="iptShareLink"
                    v-model="shareLink"
                    readonly
                  />
                  <button class="btn" v-on:click="vodCopy">複製分享網址</button>
                  <ShareNetwork
                    network="facebook"
                    :url="shareLink"
                    :title="`${seoSettings.name} ${seoSettings.title}`"
                    :quote="`${seoSettings.name} ${seoSettings.fb_title} \n ${seoSettings.description}`"
                    :hashtags="`${seoSettings.name}`"
                    :popup="{ width: 600, height: 600 }"
                  >
                    <img
                      class="share-img"
                      src="@/assets/img/fbShare.png"
                      alt=""
                    />
                  </ShareNetwork>
                  <ShareNetwork
                    network="line"
                    :url="shareLink"
                    :title="`${seoSettings.name} ${seoSettings.title} \n ${seoSettings.description}`"
                    :hashtags="`${seoSettings.name} ${seoSettings.keywords}`"
                    :popup="{ width: 600, height: 600 }"
                  >
                    <img
                      class="share-img"
                      src="@/assets/img/lineShare.png"
                      alt=""
                    />
                  </ShareNetwork>
                </p>
                <p>
                  推薦註冊人數:&nbsp;{{ userInfo.share_reg }}&nbsp;
                  <span class="mlr-40"
                    >每位贈送:&nbsp;{{ userInfo.reg_bonus }}</span
                  >

                  <button
                    v-if="bolSending == false"
                    class="btn"
                    v-on:click="bonusPickup(1)"
                  >
                    提領
                  </button>
                  <span class="mlr-40">已提領:{{ userInfo.bonus1_c }}筆</span>
                </p>
                <p>分享流量: {{ userInfo.share_view }}</p>
                <p>
                  推薦會員儲值分潤
                  <span class="mlr-40"
                    >未領取數量:{{
                      userInfo.bonus7_d_no | cleanDotFilter
                    }}希望幣</span
                  >
                  <button
                    v-if="bolSending == false"
                    class="btn"
                    v-on:click="bonusPickup(7)"
                  >
                    提領
                  </button>
                  <span class="mlr-40"
                    >已領取數量:{{
                      userInfo.bonus7_d | cleanDotFilter
                    }}希望幣</span
                  >
                </p>
              </div>
              <dl>
                <dd>註冊時間: {{ userInfo.created_at }}</dd>
                <dd>登入次數: {{ userInfo.login_time }}</dd>
                <dd>購買次數: {{ userInfo.order }}</dd>
              </dl>

              <div class="list">
                <h4>會員帳號</h4>
                <div class="ipt-ct">
                  <input type="text" v-model="userInfo.username" disabled />
                </div>
                <h4>會員暱稱</h4>
                <div class="ipt-ct">
                  <input type="text" :value="data.name" disabled />
                </div>
                <h4>會員密碼:</h4>
                <InputPassword
                  :keyy="'password'"
                  :data="data"
                  :dataDisState="dataDisState"
                  @vodEdit="vodEdit"
                  @vodSave="vodSave"
                />
                <h4>電子信箱:</h4>
                <InputText
                  :keyy="'email'"
                  :data="data"
                  :dataDisState="dataDisState"
                  @vodEdit="vodEdit"
                  @vodSave="vodSave"
                />
                <h4>會員性別:</h4>
                <InputRadio
                  :keyy="'gender'"
                  :data="data"
                  :dataDisState="dataDisState"
                  @vodEdit="vodEdit"
                  @vodSave="vodSave"
                />
                <h4>通訊地址:</h4>
                <InputText
                  :keyy="'address'"
                  :data="data"
                  :dataDisState="dataDisState"
                  @vodEdit="vodEdit"
                  @vodSave="vodSave"
                />
                <h4>Line 帳號:</h4>
                <InputText
                  :keyy="'line_id'"
                  :data="data"
                  :dataDisState="dataDisState"
                  @vodEdit="vodEdit"
                  @vodSave="vodSave"
                />
                <h4>微信帳號:</h4>
                <InputText
                  :keyy="'wx_id'"
                  :data="data"
                  :dataDisState="dataDisState"
                  @vodEdit="vodEdit"
                  @vodSave="vodSave"
                />
              </div>
            </div>
          </div>
        </div>
        <CompFoot />
      </div>
    </div>

    <div id="lbox" v-if="strPop != ''">
      <div class="shadow"></div>
      <div class="pop">
        <i class="close"></i>
        <div class="txt">
          <p>※抽獎方式：</p>
          <p>1.福利金幣抽獎區。</p>
          <p>一。參加每項抽獎獎品所需福利金幣數量皆由主辦單位設定。</p>
          <p>
            二。每項抽獎獎區皆為人人有獎，其各獎區獎品內容將顯示於各抽獎區獎項內，
          </p>
          <p>普獎為『福利銀幣』。</p>
          <p>2.福利銀幣抽獎區。</p>
          <p>一。參加每項抽獎獎品所需福利銀幣數量皆由主辦單位設定。</p>
          <p>
            二。每項抽獎獎區皆為人人有獎，其各獎區獎品內容將顯示於各抽獎區獎項內
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
  components: {
    CompMarquee: require('@/components/parts/Marquee').default,
    CompFoot: require('@/components/parts/Foot').default,
    CompMenNavmem: require('@/components/parts/Navmem').default,
    InputText: require('@/components/memberInfo/form/InputText').default,
    InputPassword: require('@/components/memberInfo/form/InputPassword')
      .default,
    InputRadio: require('@/components/memberInfo/form/InputRadio').default,
  },
  computed: {
    ...mapGetters({
      webMode: 'webMode',
    }),
    ...mapState({
      userInfo: (state) => state.member.userInfo,
      seoSettings: (state) => state.website.seoSettings,
    }),
    shareLink() {
      const shareLink = `${location.origin}/#/home/${this.userInfo.link}`;
      return shareLink;
    },
  },
  data() {
    return {
      data: {},
      dataDisState: {},
      //
      strPop: '',
      bolSending: false,
      fbShareUrl: 'https://mapi.uushope.com/member/fbShare',
      shareUrl: '',
    };
  },
  methods: {
    async getuser() {
      await this.$store.dispatch('member/getuser');
      this.handleEditFrom();
    },
    async handleEditFrom() {
      let _data = Object.assign({}, this.userInfo);
      this.data = {
        name: _data.name,
        password: _data.password,
        email: _data.email,
        gender: _data.gender,
        address: _data.address,
        line_id: _data.line_id,
        wx_id: _data.wx_id,
      };
      let dataDisState = {};
      await Object.keys(this.data).forEach((x) => {
        dataDisState[x] = false;
      });
      this.dataDisState = dataDisState;
    },
    async vodEdit(str) {
      this.dataDisState[str] = true;
      await this.$bus.emit('input.blur' + str, str);
      await this.$bus.emit('input.focus' + str, str);
    },
    async vodSave(str) {
      if (!this.data[str]) {
        this.$root.$snotify.error('未輸入任何資料');
        return;
      }
      let _d = {};
      _d[str] = this.data[str];
      const state = await this.$store.dispatch('member/edituser', _d);
      if (state) {
        this.dataDisState[str] = false;
        this.$bus.emit('input.blur' + str, str);
        this.getuser();
      }
    },
    //
    vodCopy() {
      let ipt = document.getElementsByClassName('iptShareLink');
      ipt[0].select();
      document.execCommand('copy');
      this.$root.$snotify.success('分享網址已複製', '複製成功');
    },
    async bonusPickup(type) {
      const data = {
        token: sessionStorage.token,
        type,
      };
      await this.$store.dispatch('bonus/bonusPickup', data);
      this.$store.dispatch('member/getuser');
    },
  },
  mounted() {
    this.getuser();
  },
};
</script>
<style>
@import "~@/assets/css/member-jbzl.css";
.share-img {
  height: 38px;
  vertical-align: bottom;
  margin-right: 10px;
}
.mlr-40 {
  margin: 0 40px;
}
</style>
