import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueBus from "vue-bus";
import _ from "lodash";
import * as filters from "@/plugins/filters";
import "@/plugins/permission";
//style
import "@/assets/css/public.css";
import "@/assets/css/index.css";
import "@/assets/css/flexslider.css";
//
import Snotify from "vue-snotify";
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta';

const url = location.host.indexOf('uushope') > -1 ? process.env.VUE_APP_BASEURL : process.env.VUE_APP_TEST_BASEURL
const install = Vue => {
  //
  Vue.prototype.$baseUrl = url;
  Vue.prototype.$baseImgUrl = url + "uploads/";
  Vue.prototype.$loadingCase = function (text) {
    return ElementUI.Loading.service({
      lock: true,
      text: text,
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
  };
  //
  Vue.use(ElementUI);
  Vue.prototype._ = _;
  Vue.use(VueBus);

  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
  });
  Vue.use(Snotify);
  Vue.use(VueSocialSharing);
  Vue.use(VueMeta);
  Vue.use(require('vue-wechat-title'))
  Vue.config.productionTip = false;
};

export default {
  install
};
