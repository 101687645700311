<template>
  <div>
    <div
      class="mainten-mask"
      v-if="maintenances.status === 1 && maintenances.url !== ''"
    >
      <img :src="$baseImgUrl + maintenances.url" alt="" />
    </div>
    <div v-else>
      <span id="app" v-if="seoSettings">
        <vue-snotify></vue-snotify>
        <CompHeader />
        <keep-alive>
          <router-view
            v-if="$route.meta.keepAlive"
            v-wechat-title="seoSettings.name + ' ' + seoSettings.title"
          >
          </router-view>
        </keep-alive>
        <router-view
          v-if="!$route.meta.keepAlive"
          v-wechat-title="seoSettings.name + ' ' + seoSettings.title"
        />
        <audio
          v-for="(audio, index) in audioList"
          :key="index"
          :ref="audio.code"
          :src="audio.url"
          hidden="true"
          :id="audio.code"
        ></audio>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    CompHeader: require('@/components/parts/Header').default,
  },
  computed: {
    ...mapState({
      audioList: (state) => state.audio.audioList,
      seoSettings: (state) => state.website.seoSettings,
      maintenances: (state) => state.website.maintenances,
      autoLogout: (state) => state.member.autoLogout,
      ActivityMsgNoReadCount: (state) => state.news.ActivityMsgNoReadCount,
      msgNoReadCount: (state) => state.notice.msgNoReadCount,
    }),
    ...mapGetters({
      loginState: 'loginState',
    }),
  },
  data() {
    return {
      pollingTimer: [],
    };
  },
  created() {
    this.$bus.$on('play:music', (id) => {
      this.playMusic(id);
    });
    this.$bus.$on('pause:music', ({ id, goZero }) => {
      this.pauseMusic({ id, goZero });
    });
  },
  async mounted() {
    sessionStorage.referrer || (sessionStorage.referrer = document.referrer);
    sessionStorage.totalNoReadMsgCount = 0;
    if (this.mobileCheck()) {
      location.href = location.href.replace(/uushope/, 'mobile.uushope');
    }
    await this.getAudioList();
    await this.getSeoSettings();
    await this.checkLoginStatus();
    await this.getMenu();
    await this.getMaintenancesData();
    await this.getCoinList();
    await this.getMarketingList();

    this.pollingMarquee();
  },
  methods: {
    mobileCheck() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    async getSeoSettings(lottery_id) {
      await this.$store.dispatch('website/getSeoSettings');
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.$baseImgUrl + this.seoSettings.icon;

      document.getElementsByTagName('head')[0].appendChild(link);
    },
    /** 確認登入狀態 */
    async checkLoginStatus() {
      var state = await this.$store.dispatch('member/getuser');
      if (state) {
        await this.getNoReadMsg();
        const path = this.$route.name === 'login' ? '/' : this.$route.path;
        this.$router.push(path);
      } else {
        if (this.autoLogout) {
          const path =
            this.$route.name === 'login' || this.$route.name === 'reg'
              ? this.$route.path
              : '/login';
          this.$router.push(path);
        }
      }
    },
    async getCoinList() {
      await this.$store.dispatch('coin/getCoinList');
    },
    async getMarketingList() {
      const data = await this.$store.dispatch('marketing/getMarketingList');
      if (data.status) {
        let headContent = '';
        let bodyContent = '';
        data.data.forEach((e) => {
          headContent += e.head;
          bodyContent += e.body;
        });
        let headFrag = document
          .createRange()
          .createContextualFragment(headContent);
        document.head.append(headFrag);
        let bodyFrag = document
          .createRange()
          .createContextualFragment(bodyContent);
        document.body.append(bodyFrag);
      }
    },
    async getMaintenancesData() {
      await this.$store.dispatch('website/getMaintenances');
    },
    async getNoReadMsg() {
      const data = {
        token: sessionStorage.token,
        page: 1,
        pagesize: 15,
      };
      //站內信
      const msgData = await this.$store.dispatch('notice/getMessageList', {
        _d: data,
        routeName: this.$route.name,
      });
      // 公告
      const ActivityMsgData = await this.$store.dispatch(
        'news/getActivityInfos',
        {
          _d: data,
          routeName: this.$route.name,
        }
      );
      const total = this.msgNoReadCount + this.ActivityMsgNoReadCount;
      if (total > sessionStorage.totalNoReadMsgCount * 1) {
        if (objConf.bolSnd) {
          this.$bus.$emit('play:music', 'no_read_mail');
        }
        sessionStorage.totalNoReadMsgCount = total;
      }
    },
    async getMenu() {
      await this.$store.dispatch('website/getMenu');
    },
    async getAudioList() {
      await this.$store.dispatch('audio/getAudioList');
    },
    pollingMarquee() {
      this.pollingTimer.push(
        setInterval(() => {
          this.checkLoginStatus();
        }, 30000)
      );
      this.pollingTimer.push(
        setInterval(() => {
          this.$store.dispatch('website/getMaintenances');
        }, 300000)
      );
      this.pollingTimer.push(
        (this.winmarqueeTimer = setInterval(() => {
          this.$store.dispatch('notice/getWinmarquees');
        }, this.seoSettings.winmq_carousel * 1000))
      );
      this.pollingTimer.push(
        (this.marqueeTimer = setInterval(() => {
          this.$store.dispatch('notice/getmarquees');
        }, this.seoSettings.carousel * 1000))
      );
    },
    playMusic(id) {
      const el = document.getElementById(id);
      el.volume = 0.5;
      el.play();
    },
    pauseMusic({ id, goZero }) {
      const el = document.getElementById(id);
      el.pause();
      goZero && (el.currentTime = 0);
    },
  },
  beforeDestroy() {
    this.pollingTimer.forEach((e) => clearInterval(e));
    this.$bus.$off('play:music');
    this.$bus.$off('pause:music');
  },
  metaInfo() {
    return {
      title: this.seoSettings.name + ' ' + this.seoSettings.title,
      meta: [
        { name: 'keywords', content: this.seoSettings.keywords },
        { name: 'description', content: this.seoSettings.description },
        // { property: "og:site_name", content: this.seoSettings.name },
        // { property: "og:type", content: 'website' },
        // { property: "og:title", content: this.seoSettings.name },
        // { property: "og:description", content: this.seoSettings.description },
        // { property: "og:image", content: this.$baseImgUrl + this.seoSettings.logo },
      ],
      link: [
        {
          rel: 'shortcut icon',
          type: 'image/x-icon',
          href: this.$baseImgUrl + this.seoSettings.icon,
        },
        { rel: 'icon', href: this.$baseImgUrl + this.seoSettings.icon },
        { rel: 'canonical', href: location.protocol + '//' + location.host },
      ],
    };
  },
};
</script>

<style>
.clsPlaceH::placeholder {
  color: red;
}
.snotify {
  z-index: 99999 !important;
}
#hidden-content2 {
  padding: 0px;
}
.mainten-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-size: 100%;
  z-index: 99993;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
